<template>
  <div style="display: contents">
    <v-container fluid>
      <v-card :loading="loading">
        <v-toolbar short flat>
          <v-toolbar-title> Assets </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>

          <ActionButton
            v-for="action in actions"
            :key="action.name"
            :icon="action.icon"
            :tooltip="action.text"
            :disabled="action.name == 'delete' && selected.length == 0"
            @action-click="onActionClick(action.name)"
          />
        </v-toolbar>
        <v-divider />

        <v-data-table
          class="crud-table lnp-table"
          show-select
          item-key="ASSET_ID"
          v-model="selected"
          :headers="headers"
          :items="items"
          @click:row="onRowClick"
          hide-default-footer
          dense
        >
          <template v-slot:[`item.ASSET_RESOURCE`]="{ item }">
            <a style="text-decoration: none;" target="_blank" :href="item.ASSET_RESOURCE">{{
              item.ASSET_RESOURCE
            }}</a>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <CRUDForm
      ref="crudForm"
      @save="onFormSave"
      @close="form.active = false"
      :formSize="550"
      headerText="Asset"
      :loading="form.loading"
      :formActive="form.active"
      :alwaysPostAll="true"
    >
      <template v-slot:[`form.content`]="">
        <template v-for="(item, index) in fields">
          <v-col :key="index" cols="12" v-if="hidden[item.name] == undefined || hidden[item.name]">
            <v-file-input
              v-if="item.name == 'file'"
              v-model="file"
              :label="item.text"
              :hint="values['ASSET_RESOURCE']"
              persistent-hint
              @change="values['ASSET_RESOURCE'] = null"
            ></v-file-input>
            <InputControl
              v-else
              :bind="{ row: true }"
              :key="index"
              :attrId="item.name"
              :label="item.text"
              :ref="`input_${item.name}`"
              :value.sync="values[item.name]"
              :attrType="item.input.type"
              :attrRef="item.input.lov"
              :control="item.input.control"
              :disabled="item.disabled"
              :rules="item.input.validation"
              @update:value="onChangeVal($event, item.name)"
            />
          </v-col>
        </template>
      </template>
    </CRUDForm>
  </div>
</template>

<script>
import CRUDForm from "@/components/util/CRUD/CRUDForm";
import ActionButton from "@/components/util/ActionButton";
import InputControl from "@/components/util/InputControl";

export default {
  components: {
    CRUDForm,
    ActionButton,
    InputControl
  },
  props: ["campaignId"],
  data() {
    return {
      form: {
        loading: false,
        active: false
      },
      file: undefined,
      values: {},
      hidden: {
        ASSET_URL: false,
        file: false
      },
      selected: [],
      loading: false,
      actions: [
        { name: "delete", text: "Delete", icon: "mdi-delete-forever" },
        { name: "sync", text: "Sync OCM Documents", icon: "mdi-folder-sync" },
        { name: "new", text: "New Asset", icon: "mdi-plus" }
      ],
      items: [],
      headers: [
        {
          text: "Asset Name",
          value: "ASSET_NAME"
        },
        {
          text: "File",
          value: "ASSET_RESOURCE"
        },
        {
          text: "Tech Topic",
          value: "TECHTOPIC",
          width: "150px"
        },
        {
          text: "Type",
          value: "ASSET_TYPE",
          width: "50px"
        },
        {
          text: "User",
          value: "USERNAME",
          width: "150px"
        },
        {
          text: "Last Updated",
          value: "LAST_UPDATED",
          width: "150px"
        }
      ],
      fields: [
        {
          text: "Name",
          name: "ASSET_NAME",
          input: {
            validation: ["mandatory"],
            cols: "12"
          }
        },
        {
          text: "Type",
          name: "ASSET_TYPE",
          input: {
            type: "L",
            control: "RADIO",
            lov: [
              { text: "File", value: 1 },
              { text: "URL", value: 2 }
            ],
            validation: ["mandatory"],
            cols: "12"
          }
        },
        {
          text: "Tech Topic",
          name: "TECHTOPIC_ID",
          input: {
            type: "L",
            cols: "12"
          }
        },
        {
          text: "URL",
          name: "ASSET_URL",
          input: {
            validation: ["mandatory", "url"],
            cols: "12"
          }
        },
        {
          text: "Upload File",
          name: "file",
          input: {
            validation: ["mandatory"],
            cols: "12"
          }
        }
      ]
    };
  },
  watch: {
    campaignId: {
      immediate: true,
      handler(v) {
        if (v) this.fetchList();
      }
    }
  },
  methods: {
    onChangeVal(v, field) {
      if (field == "ASSET_TYPE") {
        this.hidden["ASSET_URL"] = v == 2;
        this.hidden["file"] = v == 1;
      }
    },
    onFormSave() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("campaignId", this.campaignId);
      for (const [key, value] of Object.entries(this.values)) {
        formData.append(key, value);
      }

      this.$axios
        .post("/lnp/asset/save", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((res) => {
          if (res.data.asset_id) {
            this.$store.commit("sendAlert", {
              msg: "Asset added",
              color: "success"
            });
            this.fetchList();
            this.form.active = false;
          } else {
            this.$store.commit("sendAlert", {
              msg: "Error: " + res.data.error,
              color: "error"
            });
          }
        })
        .finally(() => {
          this.$refs.crudForm.saved();
        });
    },
    loadForm(id) {
      this.form.active = true;
      this.file = undefined;
      if (id) {
        this.form.loading = true;
        this.$axios
          .get("/lnp/asset/get", {
            params: {
              assetId: id
            }
          })
          .then((res) => {
            this.values = res.data;
            this.values.ASSET_URL = this.values.ASSET_RESOURCE;
            this.values.ASSET_CURRENT_NAME = this.values.ASSET_NAME;
            this.onChangeVal(this.values.ASSET_TYPE, "ASSET_TYPE");
          })
          .finally(() => {
            this.form.loading = false;
          });
      } else {
        this.values = { ASSET_TYPE: 1 };
        this.onChangeVal(this.values.ASSET_TYPE, "ASSET_TYPE");
      }
    },
    onActionClick(e) {
      switch (e) {
        case "new":
          this.loadForm();
          break;
        case "sync":
          this.syncDoc();
          break;
        case "delete":
          this.delete();
          break;
      }
    },
    delete() {
      let idsToDelete = this.selected.map((e) => {
        return e["ASSET_ID"];
      });

      this.$root.$confirm("Delete", "Are you sure?", { color: "red" }).then((confirm) => {
        if (confirm) {
          this.$axios
            .delete("/lnp/asset/delete", {
              params: {
                ids: idsToDelete
              }
            })
            .then((res) => {
              if (!res.data.error) {
                this.fetchList();
                this.$store.commit("sendAlert", {
                  msg: "Assets deleted",
                  color: "success"
                });
              } else {
                this.$store.commit("sendAlert", {
                  msg: res.data.error,
                  color: "error"
                });
              }
            });
        }
      });
    },
    fetchList() {
      this.selected = [];
      this.loading = true;
      this.$axios
        .get("/lnp/asset/list", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          this.items = res.data.list;
          this.fields.find((e) => e.name == "TECHTOPIC_ID").input.lov = res.data.techtopics;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClick(e) {
      this.loadForm(e.ASSET_ID);
    },
    syncDoc() {
      this.$root
        .$confirm("Sync OCM Document?", "Any changes will be overwritten by OCM copy.", {
          color: "red"
        })
        .then((confirm) => {
          if (confirm) {
            this.$axios
              .put("/lnp/asset/syncOCM", {
                campaignId: this.campaignId
              })
              .then(() => {
                this.fetchList();
                this.$store.commit("sendAlert", {
                  msg: "Assets synced",
                  color: "success"
                });
              });
          }
        });
    }
  }
};
</script>
