<template>
  <v-container fluid>
    <CampaignETEditor
      v-if="EID"
      :EID="EID"
      :templates="templates"
      :campaignId="campaignId"
      @update="fetchList"
    />
    <v-card :loading="loading" v-else>
      <v-toolbar short flat>
        <v-toolbar-title> Email Templates </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-divider class="mx-4" inset vertical></v-divider>

        <ActionButton
          v-for="action in actions"
          :key="action.name"
          :icon="action.icon"
          :tooltip="action.text"
          :disabled="action.name == 'delete' && selected.length == 0"
          @action-click="onActionClick(action.name)"
        />
      </v-toolbar>
      <v-divider />

      <v-data-table
        class="crud-table lnp-table"
        show-select
        item-key="EID"
        v-model="selected"
        :headers="headers"
        :items="items"
        @click:row="onRowClick"
        hide-default-footer
        dense
      >
      </v-data-table>
      <CRUDForm
        id="+"
        :fields="fields"
        actionBtnName="Create"
        ref="crudForm"
        @save="onFormSave"
        @close="etForm.active = false"
        :formSize="550"
        headerText="Email Template"
        :loading="etForm.loading"
        :formActive="etForm.active"
      >
      </CRUDForm>
    </v-card>
  </v-container>
</template>

<script>
import CRUDForm from "@/components/util/CRUD/CRUDForm";
import CampaignETEditor from "@/components/lnp/CampaignETEditor";
import ActionButton from "@/components/util/ActionButton";

export default {
  components: {
    CRUDForm,
    ActionButton,
    CampaignETEditor
  },
  props: ["campaignId"],
  data() {
    return {
      etForm: {
        loading: false,
        active: false
      },
      etFor: false,
      idData: null,
      selected: [],
      source: [],
      loading: false,
      searchVal: "",
      actions: [
        { name: "delete", text: "Delete", icon: "mdi-delete-forever" },
        { name: "manage", text: "Manage Preset Email Templates", icon: "mdi-folder-edit" },
        { name: "new", text: "New Email template", icon: "mdi-email-plus" }
      ],
      items: [],
      headers: [
        {
          text: "Name",
          value: "Name",
          width: "250px"
        },
        {
          text: "Subject",
          value: "Subject"
        },
        {
          text: "No. Assets",
          value: "AssetsNo",
          width: "50px"
        },
        {
          text: "Created By",
          value: "CreatedBy",
          width: "150px"
        },
        {
          text: "Last Updated",
          value: "CreatedOn",
          width: "150px"
        }
      ],
      fields: [
        {
          text: "Name",
          name: "Name",
          input: {
            validation: ["mandatory"],
            cols: "12"
          }
        },
        {
          text: "From",
          name: "ET_FROM",
          input: {
            validation: ["mandatory"],
            cols: "12",
            value: ""
          }
        },
        {
          text: "Reply To",
          name: "ET_REPLYTO",
          input: {
            cols: "12",
            value: ""
          }
        },
        {
          text: "CC",
          name: "CC",
          input: {
            cols: "12"
          }
        },
        {
          text: "Subject",
          name: "Subject",
          input: {
            validation: ["mandatory"],
            cols: "12"
          }
        },
        {
          text: "Template",
          name: "Template",
          input: {
            type: "L",
            cols: "12",
            lov: [],
            value: 3800463664,
            noPrependEmptyVal: true
          }
        }
      ],
      templates: []
    };
  },
  watch: {
    campaignId: {
      immediate: true,
      handler(v) {
        if (v) this.fetchList();
      }
    }
  },
  computed: {
    EID() {
      return this.$route.params.id;
    },
    templateDomain() {
      return `${this.$store.getters["auth/getApiHost"]["template_host"]}/templates/index.htm?ACTION=down&DIR_HIDE=1&DIR_TREE=&DIR=%2Fdirect%2Flnp%2F${this.$store.state.auth.user.areaid}`;
    }
  },
  created() {
    this.fetchETForm();
  },
  methods: {
    onFormSave(e) {
      this.$axios
        .post("/lnp/emailtemplate/create_email_template", {
          campaignId: this.campaignId,
          data: e
        })
        .then((res) => {
          this.$refs.crudForm.saved();
          if (res.data.EID) {
            this.etForm.active = false;
            this.fetchList();
            this.$router.push({
              params: {
                id: res.data.EID
              },
              query: {
                template: e.find((x) => x.f == "Template").v
              }
            });
          } else {
            this.$store.commit("sendAlert", {
              msg: res.data.error,
              color: "error"
            });
          }
        });
    },
    loadETForm() {
      this.etForm.active = true;
      this.$refs.crudForm.load({});
    },
    fetchETForm() {
      this.etForm.loading = true;
      this.$axios
        .get("/lnp/emailtemplate/get_et_default", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          for (let fieldName of Object.values(["ET_FROM", "ET_REPLYTO"])) {
            //this.$refs.crudForm.updateValue(fieldName, res.data[fieldName]);
            this.fields.find((e) => e.name == fieldName).input.value = res.data[fieldName];
          }

          this.templates = res.data.templates;
          this.fields.find((e) => e.name == "Template").input.lov = this.templates;
          this.loading = false;
        })
        .finally(() => {
          this.etForm.loading = false;
        });
    },
    onActionClick(e) {
      switch (e) {
        case "new":
          this.loadETForm();
          break;
        case "manage":
          this.goTemplate();
          break;
        case "delete":
          this.deleteTemplate();
          break;
      }
    },
    deleteTemplate() {
      let idsToDelete = this.selected.map((e) => {
        return e["EID"];
      });

      this.$root.$confirm("Delete", "Are you sure?", { color: "red" }).then((confirm) => {
        if (confirm) {
          this.$axios
            .delete("/lnp/emailtemplate/delete_email_template", {
              params: {
                EIDs: idsToDelete
              }
            })
            .then((res) => {
              if (res.data) {
                this.fetchList();
                this.$store.commit("sendAlert", {
                  msg: "Email template deleted",
                  color: "success"
                });
              } else {
                this.$store.commit("sendAlert", {
                  msg: "Deletion failed",
                  color: "error"
                });
              }
            });
        }
      });
    },
    goTemplate() {
      window.open(
        this.templateDomain,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=700,height=700"
      );
    },
    fetchList() {
      this.selected = [];
      this.loading = true;
      this.$axios
        .get("/lnp/emailtemplate/get_et_list", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClick(e) {
      this.$router.push({
        params: {
          id: e.EID
        }
      });
    }
  }
};
</script>
