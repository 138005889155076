<template>
  <div style="display:content">
    <v-row no-gutters style="flex-wrap: nowrap;">
      <v-col cols="2" style="max-width: 200px;">
        <v-card outlined tile class="lnp-menu">
          <v-list dense>
            <template v-for="(item, i) in menu">
              <template v-if="!(campaignId == '+' && i > 0)">
                <v-list-item v-if="!item.items" :key="i" :to="item.to">
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>

                <v-list-group v-else :key="i" no-action :value="true">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item v-for="child in item.items" :key="child.text" :to="child.to" exact>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="child.text"
                        :class="!child.isActionItem || 'font-weight-regular blue--text'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="10" class="flex-grow-1 flex-shrink-1">
        <v-card outlined tile>
          <keep-alive>
            <Assets :campaignId="campaignId" v-if="tab == 'asset'" />
            <Design :campaignId="campaignId" v-else-if="tab == 'db-design'" />
            <DataUpload :campaignId="campaignId" v-else-if="tab == 'data-upload'" />
            <DBView :campaignId="campaignId" v-else-if="tab == 'db-view'" />
            <CampaignET :campaignId="campaignId" v-else-if="tab == 'email-templates'" />
            <ProjectListing
              :campaignId="campaignId"
              :projectId="id"
              v-else-if="tab == 'project'"
              @update="loadProjects"
            />
            <Info :campaignId="campaignId" :classId="classId" v-else />
          </keep-alive>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Info from "@/components/lnp/CampaignInfo";
import Assets from "@/components/lnp/CampaignAssets";
import ProjectListing from "@/components/lnp/Project/Listing";
import CampaignET from "@/components/lnp/CampaignET";
import DBView from "@/components/database/data/DBView";
import DataUpload from "@/components/lnp/DBManagement/DataUpload";
import Design from "@/components/lnp/DBManagement/Design";

export default {
  components: {
    DBView,
    Info,
    Assets,
    ProjectListing,
    CampaignET,
    DataUpload,
    Design
  },
  computed: {
    tab() {
      return this.$route.params.tab;
    },
    id() {
      //id could mean different thing according to tab
      return this.$route.params.id;
    }
  },
  props: ["campaignId", "classId"],
  watch: {
    campaignId: {
      immediate: true,
      handler(v) {
        if (!isNaN(v)) {
          this.loadProjects();
        }
      }
    }
  },
  data() {
    return {
      menu: [
        {
          text: "Info",
          to: { params: { tab: "info", id: null } }
        },
        {
          text: "DB Management",
          items: [
            { text: "Database Design", to: { params: { tab: "db-design", id: null } } },
            { text: "Data Upload", to: { params: { tab: "data-upload", id: null } } },
            { text: "Database View", to: { params: { tab: "db-view", id: null } } }
          ]
        },
        {
          text: "Assets",
          to: { params: { tab: "asset", id: null } }
        },
        {
          text: "Email Templates",
          to: { params: { tab: "email-templates", id: null } }
        },
        {
          text: "Projects",
          items: []
        }
      ],
      loading: null,
      content: "",
      selectedItem: null
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    changeTab(tab) {
      this.content = tab;
    },
    loadProjects() {
      let projectMenu = this.menu.find((e) => e.text == "Projects");
      projectMenu.items = [
        {
          text: "Manage Projects",
          to: { params: { tab: "project", id: null } },
          isActionItem: true
        }
      ];

      this.loading = true;
      this.$axios
        .get("/lnp/get_project_list", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          if (res.data) {
            let pList = res.data.map((p) => {
              return {
                text: p.PROJECT_NAME,
                to: { params: { tab: "project", id: p.PROJECT_ID } }
              };
            });
            projectMenu.items.unshift(...pList);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
