<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container fluid style="padding:30px 50px">
      <v-row>
        <template v-for="(item, index) in fields">
          <v-col :key="index" :cols="item.col ? item.col : 6">
            <InputControl
              :key="index"
              :attrId="item.field"
              :label="item.label"
              :ref="`input_${item.field}`"
              :value.sync="values[item.field]"
              :attrType="item.type"
              :attrRef="lov[item.field]"
              :multiValue="item.multiple"
              :control="item.control"
              :disabled="item.noEdit && campaignId != '+'"
              :rules="item.validation"
            />
          </v-col>
        </template>
        <v-col cols="6" v-if="campaignId && values.ADD_DATE">
          <div class="caption">
            Created on <b>{{ this.values.ADD_DATE }}</b> by <b> {{ this.values.USERNAME }} </b>
          </div>
        </v-col>
        <v-col cols="6" v-if="campaignId && values.MOD_DATE">
          <div class="caption">
            Modified on <b>{{ this.values.MOD_DATE }}</b> by <b>{{ this.values.MOD_USERNAME }}</b>
          </div>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="save" :loading="loading" :disabled="!valid">Save</v-btn>
      </v-card-actions>
    </v-container>
  </v-form>
</template>

<script>
import InputControl from "@/components/util/InputControl";

export default {
  components: {
    InputControl
  },
  props: {
    campaignId: {
      required: true
    },
    classId: {
      required: true
    }
  },
  watch: {
    campaignId: {
      immediate: true,
      handler() {
        this.loadForm();
      }
    }
  },
  data() {
    return {
      loading: false,
      values: {},
      lov: {},
      modifiedFields: [],
      valid: true,
      fields: [
        { field: "CAMPAIGN_NAME", label: "Program Name", validation: ["mandatory"] },
        { field: "CLIENT", label: "Client", type: "L", noEdit: true, validation: ["mandatory"] },
        { field: "START_DATE", label: "Start Date", type: "D" },
        { field: "END_DATE", label: "End Date", type: "D" },
        { field: "STATUS", label: "Status", type: "L" },
        { field: "TIMEZONE", label: "Location", type: "L", noEdit: true },
        { field: "PRI_TT", label: "Primary Tech Topic", type: "L", validation: ["mandatory"] },
        {
          field: "SEC_TT",
          label: "Secondary Tech Topic",
          type: "L",
          multiple: true,
          validation: ["mandatory"]
        },
        { field: "CM_NOTES", label: "CM Notes" },
        {
          field: "OPTINTOTR",
          label: "Enable TR Registration Option",
          multiple: false,
          control: "CHECKBOX"
        }
      ]
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.$axios({
        method: this.campaignId == "+" ? "post" : "put",
        url: "/lnp/save_campaign",
        data: this.values
      })
        .then((res) => {
          if (!res.data || res.data.error) {
            this.$store.commit("sendAlert", {
              msg: res.data.message || "Nothing returned from api",
              color: "error"
            });
          } else {
            if (this.campaignId == "+") {
              this.$router.push({ params: { campaignId: res.data.object_id } });
            } else {
              this.fetchCampaign();
            }
            this.$store.commit("sendAlert", {
              msg: "Campaign saved",
              color: "success"
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCampaign() {
      this.loading = true;
      this.$axios
        .get("/lnp/get_campaign", {
          params: {
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          this.values = res.data;
          this.values.OBJECT_ID = this.campaignId;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchLov() {
      this.$axios.get("/lnp/get_campaign_lov").then((res) => {
        this.lov = res.data;
      });
    },
    loadForm() {
      if (this.campaignId != "+") this.fetchCampaign();
      else this.values.CLASS_ID = this.classId;
      this.fetchLov();
    }
  }
};
</script>
