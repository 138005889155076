<template>
  <div style="display: contents">
    <v-toolbar short flat tile>
      <v-btn icon @click="back">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <v-toolbar-title>Edit Template: {{ template.ET_NAME }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="save()" :loading="loading">
        Save
      </v-btn>
    </v-toolbar>

    <v-toolbar color="#f5f5f5">
      <template v-if="!template.DIR_OBJECT_ID">
        <v-select
          :value="templateSelect"
          :items="templates"
          label="Templates"
          hide-details
          outlined
          dense
          style="max-width: 300px"
          @change="changeTemplate"
        ></v-select>

        <v-divider class="mx-4" inset vertical></v-divider>
      </template>

      <v-select
        return-object
        v-model="value.cam"
        :items="lov.cam_assets"
        label="Campaign Assets"
        hide-details
        outlined
        dense
        style="max-width: 300px"
      ></v-select>

      <v-btn :disabled="!value.cam" small icon @click="insert(value.cam, 'cam')" class="mr-2">
        <v-icon> mdi-plus </v-icon>
      </v-btn>

      <v-select
        return-object
        v-model="value.tr"
        :items="lov.tr_assets"
        label="TR Directory Asset"
        hide-details
        outlined
        dense
        style="max-width: 300px"
      ></v-select>
      <v-btn :disabled="!value.tr" small icon @click="insert(value.tr, 'tr')" class="mr-2">
        <v-icon> mdi-plus </v-icon>
      </v-btn>

      <v-select
        v-model="value.attr"
        :items="lov.attributes"
        label="Attributes"
        hide-details
        outlined
        dense
        style="max-width: 300px"
      ></v-select>
      <v-btn :disabled="!value.attr" small icon @click="insert(value.attr, 'attr')" class="mr-2">
        <v-icon> mdi-plus </v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <ActionButton icon="mdi-file-find" tooltip="Preview" @action-click="previewCode" />
    </v-toolbar>
    <prism-editor
      ref="editor"
      lang="html"
      :style="{ height: '800px' }"
      v-model="code"
    ></prism-editor>
    <v-dialog v-model="dialog" @keydown.esc="dialog = false" max-width="800px">
      <iframe ref="previewIF" height="800" width="800" style="background-color: white;" />
    </v-dialog>
  </div>
</template>

<script>
import PrismEditor from "@/components/util/PrismEditor";
import ActionButton from "@/components/util/ActionButton";

export default {
  components: {
    ActionButton,
    PrismEditor
  },
  props: ["EID", "campaignId", "templates"],
  computed: {
    templateSelect() {
      return this.$route.query.template || "";
    }
  },
  data: () => ({
    code: "",
    template: {},
    lov: {},
    value: {},
    dialog: false,
    loading: false
  }),
  watch: {
    EID: {
      immediate: true,
      handler(v) {
        if (v) {
          this.fetchET(v);
        }
      }
    }
  },
  methods: {
    changeTemplate(e) {
      if (this.templateSelect != e) {
        this.$router.push({
          query: {
            template: e
          }
        });
      }

      if (!e) {
        this.code = "";
      } else {
        this.code = "loading...";
        this.$axios
          .get("/lnp/emailtemplate/get_template", {
            params: {
              DIR_OBJECT_ID: this.templateSelect
            }
          })
          .then((res) => {
            this.code = res.data.body;
          });
      }
    },
    previewCode() {
      this.dialog = !this.dialog;
      this.$nextTick(() => {
        const iframe = this.$refs.previewIF;
        let html_string = this.code;
        iframe.src = "data:text/html;charset=utf-8," + escape(html_string);
      });

      //this.$refs.previewIF.body.innerHTML = this.code;
    },
    preview() {
      this.previewMarkup = !this.previewMarkup;
    },
    insert(item, type) {
      let val = "";
      if (type == "attr") {
        val = `%${item}%`;
      } else {
        val = `<a target="_blank" href="${item.value}">${item.text}</a>`;
      }

      let txtarea = this.$refs.editor.$el.querySelector("textarea");
      let scrollPos = txtarea.scrollTop;
      let strPos = txtarea.selectionStart;

      let front = this.code.substring(0, strPos);
      let back = this.code.substring(strPos, this.code.length);
      this.code = front + val + back;
      strPos = strPos + val.length;
      this.$nextTick(() => {
        txtarea.selectionStart = strPos;
        txtarea.selectionEnd = strPos;
        txtarea.focus();
        txtarea.scrollTop = scrollPos;
      });
    },
    save() {
      this.loading = true;
      this.$axios
        .put("/lnp/emailtemplate/save_email_template", {
          EID: this.EID,
          campaignId: this.campaignId,
          BODY: this.code
        })
        .then((res) => {
          if (!res.data.error) {
            this.$store.commit("sendAlert", {
              msg: "Email template saved",
              color: "success"
            });
            if (this.templateSelect) {
              this.$router.replace({
                query: null
              });
            }
            this.$emit("update", true);
          } else {
            this.$store.commit("sendAlert", {
              msg: res.data.error,
              color: "error"
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        params: {
          id: null
        }
      });
    },
    fetchET(EID) {
      this.loading = true;
      this.$axios
        .get("/lnp/emailtemplate/get_et", {
          params: {
            EID: EID,
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          this.template = res.data.template;
          if (!this.template.DIR_OBJECT_ID) {
            this.changeTemplate(this.templateSelect);
          } else {
            this.code = this.template.BODY;
          }
          this.lov = res.data.lov;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
