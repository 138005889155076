<template>
  <div>
    <v-breadcrumbs style="padding: 0 0 7px 6px" :items="breadcrumbs" divider=">">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item exact :to="item.to" :disabled="item.disabled" v-if="!item.subfolder">
          {{ item.text }}
        </v-breadcrumbs-item>
        <template v-else>
          <select class="folderSelect" v-model="selectedFolder" @change="onChangeFolder">
            <option value="">Navigate subfolders</option>
            <option v-for="option in item.subfolder" :value="option.value" :key="option.value">
              {{ option.text }}
            </option>
          </select>
        </template>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  props: ["campaignId", "classId"],
  computed: {
    ids() {
      return this.campaignId + this.classId;
    }
  },
  watch: {
    ids: {
      immediate: true,
      handler() {
        this.load();
      }
    }
  },
  data() {
    return {
      selectedFolder: "",
      breadcrumbs: [
        {
          text: "Root",
          to: { params: { classId: 0 } }
        },
        {
          text: "fetching folders...",
          disabled: true
        }
      ]
    };
  },
  methods: {
    onChangeFolder() {
      this.$router.push({
        params: { classId: this.selectedFolder, campaignId: null, tab: null, id: null }
      });
    },
    load() {
      this.selectedFolder = "";
      this.$axios
        .get("/lnp/get_breadcrumbs", {
          params: {
            classId: this.classId,
            campaignId: this.campaignId
          }
        })
        .then((res) => {
          if (res.data) {
            this.breadcrumbs = res.data.map((c) => {
              return {
                text: c.text,
                to: c.subfolder
                  ? null
                  : { params: { classId: c.classId, campaignId: null, tab: null, id: null } },
                disabled: (c.classId == this.classId && !this.campaignId) || !!c.campaignId,
                subfolder: c.subfolder
              };
            });
          }
        });
    }
  }
};
</script>

<style scoped>
.folderSelect {
  font-size: 14px;
  -webkit-appearance: auto;
  border-style: solid;
  background-color: white;
}
</style>
